import md5 from 'js-md5'
export var SearchList = [
  { labe: '招标编号', code: 'name', type: 'input' },
  { labe: '项目名称', code: 'code', type: 'input' },
  {
    labe: '状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '待报价', value: '1' },
      { label: '待评标', value: '2' },
      { label: '待开标', value: '3' },
      { label: '已结束', value: '4' },
      { label: '待复标报价', value: '5' }
    ]
  },
  {
    labe: '开标日期',
    code: 'startData',
    type: 'daterange'
  },
  { labe: '公司名称', code: 'company', type: 'input', placeholder: '编码/名称' }
]
export var SearchData = {
  name: '',
  code: '',
  startData: '',
  status: '',
  company: ''
}
export var tableField = [
  { label: '招标编号', code: 'bidCode', type: 'input', width: '' },
  { label: '项目名称', code: 'itemName', type: 'input', width: '' },
  { label: '开标日期', code: 'bidStartDate', type: 'input', width: '' },
  { label: '公司名称', code: 'companyName', type: 'input', width: '' },
  { label: '发布人', code: 'createUserName', type: 'input', width: '' },
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '',
    handle: function (index, data) {
      const color = md5(data.status + '').substring(0, 6)
      const label = data.status === 1 ? '待报价' : data.status === 2 ? '待评标' : data.status === 3 ? '待开标' : data.status === 4 ? '已结束' : data.status === 5 ? '待复标报价' : ''
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      },
      {
        actionLabel: '报价',
        operationButton: 'offer',
        stateField: 'tenderType',
        stateSymbol: '=',
        stateValue: '待复标报价'
      }
    ]
  }
]

export var tableData = []
