<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <global-table :tableField="tableField" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
  </div>
</template>

<script>
import ListSearch from '@/components/actionList/ListSearch.vue'
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { SearchList, SearchData, tableField, tableData } from './js/setting.js'
import { request } from '@/assets/js/http.js'
import dayjs from 'dayjs'

export default {
  components: { ListSearch, GlobalTable },
  name: 'MyBid',
  data () {
    return {
      tableField: tableField,
      tableData: tableData,
      paginationData: {},
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.myBidData()
  },

  methods: {
    // 获取我的投标列表数据
    myBidData () {
      var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
      if (pageSize < 10) {
        pageSize = 10
      }
      const obj = {
        bidCode: this.childData.searchData.name,
        itemName: this.childData.searchData.code,
        status: this.childData.searchData.status,
        bidStartTime: this.dateFormate(this.childData.searchData.startData[0]),
        bidEndTime: this.dateFormate(this.childData.searchData.startData[1]),
        company: this.childData.searchData.company
      }
      request('/api/bid/myBidList?' + 'pageNum=' + 1 + '&' + 'pageSize=' + pageSize, 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.paginationData = { pageSize: pageSize, total: res.data.total }
        }
      })
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD HH:mm:ss')
    },
    // 点击操作里的按钮进行操作
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'MyBidRating' }, query: { id: data.bidId, status: data.status } })
        this.$emit('OpenView', { openView: 'MyBidRating' })
      }
    },
    // 查询
    onSearch (data) {
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.paginationData.pageSize = data
      this.myBidData()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
</style>
